.privacy-policy-container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.privacy-policy-paper {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy-paper h1 {
    color: #333;
    margin-bottom: 1rem;
}

.privacy-policy-paper h6 {
    color: #444;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.privacy-policy-paper p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 1rem;
}

@media (max-width: 600px) {
    .privacy-policy-container {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .privacy-policy-paper {
        border-radius: 0;
    }
} 