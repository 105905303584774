/* ProfilePage.css */

.profile-page {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 1px; /* Adjust this value to match the height of your navbar */
}

.profile-page-logged-in {
  justify-content: space-evenly;
}

.profile-page-logged-out {
  padding-top: 100px;
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6aaa64; /* Changed to Wordle green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #4e7b4d; /* Darker shade for hover effect */
}

.logout-icon-container {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.logout-icon-container svg {
    width: 18px;
    height: 18px;
    color: #e8eaed;
}

.container {
  padding-top: 20px;
}

.notification-icon-container {
    position: absolute;
    top: 70px;  /* Position it below logout button */
    right: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notification-icon-container svg {
    width: 18px;
    height: 18px;
}

.delete-account-icon-container {
    position: absolute;
    top: 120px;  /* Position it below notification button */
    right: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.3s;
}

.delete-account-icon-container:hover {
    opacity: 0.8;
}

.delete-account-icon-container svg {
    width: 18px;
    height: 18px;
}

.login-error {
    color: #d32f2f;
    background-color: #ffebee;
    padding: 8px 16px;
    border-radius: 4px;
    margin: 8px 0;
    text-align: center;
}

.google-login-button {
    background-color: #4285f4;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.google-login-button:hover {
    background-color: #357abd;
}